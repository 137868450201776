import React                  from 'react';
import {
  apiErrors,
  dmpErrors,
  jsSoftwareErrors,
  jwtErrors,
  softwareErrors as softwareErrorsConnector,
  vitaleErrors,
  errorTypes as connectorErrorTypes,
  esrestSoftwareErrors,
  dpErrors, apCvErrors,
}                             from 'dmpconnectjsapp-base/errors';
import { handleLicenseError } from 'dmpconnectjsapp-base/sagas/errorHandlersSagas';
import { errorActions }       from './errorActions';
import {
  expiredSessionErrorHandler,
  handleDocumentNotFoundError,
  invalidPracticeLocationErrorHandler,
}                                   from '../sagas/errorHandlersSagas';
import { httpErrors, openIDErrors } from './errorConstants';

export const errorSeverities = {
  LOW_SEVERITY : 'LOW_SEVERITY',
  HIGH_SEVERITY: 'HIGH_SEVERITY',
};

export const errorTypes     = {
  ...connectorErrorTypes,
  httpErrors: 9997,
  openIDErrors: 9998,
};
export const softwareErrors = softwareErrorsConnector;

export const softwareErrorDefaultDescription = {
  simplifiedDisplay: undefined,
};
const defaultDetailsFields                   = [
  { label: 'Détails', field: 's_apiErrorExtendedInformations' },
];

export const httpErrorDefaultDescription = {
  title: 'Erreur réseau',
  action: errorActions.NONE,
  severity: errorSeverities.HIGH_SEVERITY,
  showDetails  : true,
  modal        : true,
}

export const dmpConnectJSErrorDefaultDescription = {
  title        : 'Un bug logiciel s\'est produit.',
  messageField : 's_apiErrorDescription',
  action       : errorActions.CLOSE_SESSION,
  severity     : errorSeverities.HIGH_SEVERITY,
  showDetails  : true,
  modal        : true,
  detailsFields: defaultDetailsFields,
};


export const openIDErrorDefaultDescription = {
  title        : 'Erreur lors de la connexion OpenID',
  messageField : 's_apiErrorDescription',
  action       : errorActions.NONE,
  severity     : errorSeverities.HIGH_SEVERITY,
  showDetails  : true,
  modal        : false,
  logs         : false,
  detailsFields: defaultDetailsFields,
};

export const dmpConnectTLSiErrorDefaultDescription = {
  title        : 'Erreur lors de l\'accès au téléservice',
  messageField : 's_apiErrorTlsiErrorDetailError',
  detailsFields: [
    { label: 'Code d\'erreur', field: 's_apiErrorTlsiErrorDetailCode' },
    { label: 'Détails', field: 's_apiErrorTlsiErrorSoapReason' },
    { label: 'Détails complémentaires', field: 's_apiErrorExtendedInformations' },
  ],
  action       : errorActions.NONE,
  severity     : errorSeverities.HIGH_SEVERITY,
  showDetails  : true,
  modal        : true,
};

export const dmpConnectMSSErrorDefaultDescription = {
  title        : 'Erreur MSS',
  action       : errorActions.NONE,
  messageField : 's_apiErrorDescription',
  severity     : errorSeverities.LOW_SEVERITY,
  showDetails  : true,
  modal        : true,
  detailsFields: defaultDetailsFields,
};

export const dmpErrorDefaultDescription = {
  title        : 'Erreur DMP',
  action       : errorActions.GO_TO_DASHBOARD,
  messageField : 's_apiErrorDmpErrorDetails',
  severity     : errorSeverities.LOW_SEVERITY,
  showDetails  : true,
  modal        : true,
  detailsFields: [
    { label: 'Code d\'erreur', field: 's_apiErrorDmpErrorCode' },
    { label: 'Détails complémentaires', field: 's_apiErrorExtendedInformations' },
  ],
};

export const dpErrorDefaultDescription = {
  title        : 'Une erreur s\'est produite lors de l\'accès au DP.',
  messageField : 's_apiErrorDescription',
  action       : errorActions.NONE,
  severity     : errorSeverities.LOW_SEVERITY,
  showDetails  : true,
  modal        : true,
  detailsFields: defaultDetailsFields,
};

export const vitaleErrorDefaultDescription = {
  title        : 'Une erreur technique s\'est produite lors de la lecture de la carte.',
  action       : errorActions.REFRESH_PAGE,
  severity     : errorSeverities.LOW_SEVERITY,
  showDetails  : true,
  modal        : true,
  messageField : 's_apiErrorDescription',
  detailsFields: defaultDetailsFields,
};

export const curlErrorDefaultDescription = {
  title        : 'Une erreur réseau s\'est produite.',
  showDetails  : true,
  action       : errorActions.CLOSE_SESSION,
  severity     : errorSeverities.HIGH_SEVERITY,
  messageField : 's_apiErrorDescription',
  detailsFields: defaultDetailsFields,
};

export const remoteErrorDefaultDescription = {
  title        : 'Mode pilotable - Erreur',
  showDetails  : true,
  action       : errorActions.NONE,
  severity     : errorSeverities.LOW_SEVERITY,
  detailsFields: defaultDetailsFields,
  messageField : 's_apiErrorDescription',
};

export const jwtErrorDefaultDescription = {
  title        : 'Erreur JWT',
  message      : 'Le jeton fourni n\'est pas valide',
  action       : errorActions.LOGIN,
  severity     : errorSeverities.HIGH_SEVERITY,
  modal        : true,
  detailsFields: defaultDetailsFields,
};

export const dmpConnectorErrors = {
  [errorTypes.ApiErrors]                   : {
    [apiErrors.INVALID_CALL]                : {
      title      : 'Une erreur technique s\'est produite.',
      action     : errorActions.CLOSE_SESSION,
      showDetails: true,
    },
    [apiErrors.INVALID_PARAMETER]           : {
      title      : 'Un bug logiciel s\'est produit.',
      message    : 'Un paramètre est invalide',
      action     : errorActions.REFRESH_PAGE,
      showDetails: true,
    },
    [apiErrors.OPERATION_FAILED]            : {
      title        : 'Une erreur technique s\'est produite.',
      action       : errorActions.REFRESH_PAGE,
      messageField : 's_apiErrorExtendedInformations',
      detailsFields: '',
      showDetails  : true,
      modal        : true,
    },
    [apiErrors.CONCURRENT_ACCESS_ERROR]     : {
      title      : 'Une erreur technique s\'est produite.',
      message    : 'Le service DmpConnect est bloqué et doit être redémarré manuellement. Merci de transmettre au service technique les informations ci-dessous.',
      action     : errorActions.CLOSE_SESSION,
      showDetails: true,
    },
    [apiErrors.CPX_EXPIRED]                 : {
      title  : 'La carte CPS est expirée.',
      message: '',
      modal  : false,
      logs   : false,
    },
    [apiErrors.CPX_READ_ERROR]              : {
      title  : 'La lecture de la CPS a échoué.',
      message: '',
      modal  : false,
    },
    [apiErrors.CPX_INVALIDPINCODE]          : {
      title  : 'Le code porteur saisi est invalide.',
      message: '',
      modal  : false,
      logs   : false,
    },
    [apiErrors.CPX_BLOCKED]                 : {
      title  : 'La carte CPS est bloquée suite à un trop grand nombre d\'erreurs de saisie du code porteur.',
      message: 'Il est nécessaire de la débloquer avec CPS Gestion (Windows) et votre code de réinitialisation personnel.',
      modal  : false,
    },
    [apiErrors.CPX_MISSING]                 : {
      title  : 'Pas de carte détectée dans le lecteur.',
      message: 'Merci de vérifier la configuration des lecteurs, ou d\'insérer la carte.',
      modal  : false,
    },
    [apiErrors.CPX_STATUSCHANGED]           : {
      title      : 'Une erreur s’est produite avec la carte CPS.',
      message    : 'Merci de transmettre au service technique les informations ci-dessous.',
      action     : errorActions.CLOSE_SESSION,
      showDetails: true,
    },
    [apiErrors.CPX_UNKNOWNCARD]             : {
      title  : 'La carte insérée n\'est pas une CPS.',
      message: 'Veuillez vérifier que les cartes CPS et Vitale ne sont pas inversées par exemple.',
      action : errorActions.NONE,
      modal  : true,
    },
    [apiErrors.LICENSE_INTEGRATOR_DISABLED] : {
      title       : 'Votre licence d\'utilisation est expirée.',
      message     : 'Merci de contacter le service commercial.',
      errorHandler: handleLicenseError,
      modal       : true,
      showDetails : true,
      severity    : errorSeverities.HIGH_SEVERITY,
      action      : errorActions.LOGIN,
      logs        : false,
    },
    [apiErrors.LICENSE_VERSION_DISABLED]    : {
      title       : 'Cette version du connecteur DmpConnect-JS n\'est plus utilisable.',
      message     : 'Merci de le mettre à jour DmpConnect-JS..',
      errorHandler: handleLicenseError,
      modal       : true,
      showDetails : true,
      severity    : errorSeverities.HIGH_SEVERITY,
      action      : errorActions.LOGIN,
      logs        : false,
    },
    [apiErrors.LICENSE_USER_DISABLED]       : {
      title       : 'Votre licence d\'utilisation est invalide.',
      message     : 'Merci de contacter le service commercial.',
      errorHandler: handleLicenseError,
      modal       : true,
      showDetails : true,
      severity    : errorSeverities.HIGH_SEVERITY,
      action      : errorActions.LOGIN,
      logs        : false,
    },
    [apiErrors.LICENSE_USER_DOES_NOT_EXISTS]: {
      title       : 'Votre licence d\'utilisation est invalide.',
      message     : 'Merci de contacter le service commercial.',
      errorHandler: handleLicenseError,
      modal       : true,
      showDetails : true,
      severity    : errorSeverities.HIGH_SEVERITY,
      action      : errorActions.LOGIN,
      logs        : false,
    },
    [apiErrors.LICENSE_UNKNOWN_STATE]       : {
      title       : 'Une erreur de validation de votre licence d\'utilisation s\'est produite.',
      message     : 'Merci de contacter le service commercial.',
      errorHandler: handleLicenseError,
      modal       : true,
      showDetails : true,
      severity    : errorSeverities.HIGH_SEVERITY,
      action      : errorActions.LOGIN,
    },
    [apiErrors.INVALID_ins]                 : {
      title  : 'L\'INS fourni est invalide.',
      message: 'L\'INS fourni est invalide.',
      modal  : false,
      logs   : false,
    },
    [apiErrors.DEMO_CARD_IN_PROD]           : {
      title  : 'La carte est invalide',
      modal  : true,
      message: 'Utiliser une carte de demo/test sur un environnement de production est interdit.',
      action : errorActions.NONE,
      logs   : false,
    },
    [apiErrors.DOCUMENT_NOT_FOUND]          : {
      title       : 'Document inexistant',
      modal       : true,
      message     : 'Le document est introuvable, il sera supprimé de la liste des documents',
      action      : errorActions.NONE,
      errorHandler: handleDocumentNotFoundError,
      logs        : false,
    },
    [apiErrors.NO_NIR_FOUND]                : {
      message: 'Aucun INS-NIR attaché.',
      modal  : false,
      logs   : false,
    },
    [apiErrors.VITALE_CHANGED]              : {
      title  : 'La carte vitale a changé',
      message: 'Veuillez relire la carte',
      modal  : false,
      action : errorActions.NONE,
      logs   : false,
    },
    [apiErrors.NTP_FAILED]                  : {
      title   : 'Erreur Réseau',
      modal   : true,
      message : (
        <>
          Le connecteur DmpConnect-JS2 a rencontré une erreur lors de la synchronisation au serveur NTP
          <br/>
          Veuillez vérifier la configuration du connecteur.
        </>
      ),
      action  : errorActions.CLOSE_SESSION,
      severity: errorSeverities.HIGH_SEVERITY,
    },
    [apiErrors.NETWORK_TEST_FAILED]         : {
      title   : 'Erreur Réseau',
      modal   : true,
      message : (
        <>
          Le connecteur DmpConnect-JS2 ne peut accéder au réseau extérieur.
          <br/>
          Veuillez vérifier la configuration du connecteur.
        </>
      ),
      action  : errorActions.CLOSE_SESSION,
      severity: errorSeverities.HIGH_SEVERITY,
    },
    [apiErrors.APCV_CONTEXT_EXPIRED]        : {
      title  : 'Erreur ApCV',
      message: (
        <>
          Le contexte ApCV a expiré.
          <br/>
          Veuillez relire la Carte Vitale.
        </>
      ),
      action : errorActions.READ_VITALE_CARD,
    },
  },
  [errorTypes.DMPErrors]                   : {
    [dmpErrors.unknownError]: {
      title  : 'Erreur inconnue',
      action : errorActions.NONE,
    },
    [dmpErrors.DMPTooManyResult]                  : {
      title  : 'Trop de résultats.',
      message: 'Veuillez affiner votre recherche.',
      modal  : false,
      logs   : false,
    },
    [dmpErrors.DMPAuthorizationNotFound]          : {
      title  : dmpErrorDefaultDescription.title,
      action : errorActions.GO_TO_DASHBOARD,
      message: 'Pas d\'autorisation d\'accès à ce DMP.',
      logs   : false,
    },
    [dmpErrors.DMPAuthorizationExpired]           : {
      title  : dmpErrorDefaultDescription.title,
      action : errorActions.GO_TO_DASHBOARD,
      message: 'L\'autorisation d\'accès à ce DMP a expiré.',
      logs   : false,
    },
    [dmpErrors.DMPAccessDeniedByRightsService]    : {
      title  : dmpErrorDefaultDescription.title,
      action : errorActions.GO_TO_DASHBOARD,
      message: 'Cette opération DMP est interdite.',
    },
    [dmpErrors.DMPClosed]                         : {
      title  : dmpErrorDefaultDescription.title,
      action : errorActions.GO_TO_DASHBOARD,
      message: 'Le DMP est fermé.',
      logs   : false,
    },
    [dmpErrors.DMPFound]                          : {
      title  : dmpErrorDefaultDescription.title,
      action : errorActions.GO_TO_DASHBOARD,
      message: 'Le DMP existe déjà.',
      logs   : false,
      modal  : false,
    },
    [dmpErrors.DMPIdentityCertificationFailure]   : {
      title : dmpErrorDefaultDescription.title,
      action: errorActions.GO_TO_DASHBOARD,
      modal : false,
    },
    [dmpErrors.DMPPatientAccessNotFound]          : {
      title : dmpErrorDefaultDescription.title,
      action: errorActions.GO_TO_DASHBOARD,
      modal : false,
    },
    [dmpErrors.DMPPatientAccessOTPNotFound]       : {
      title : dmpErrorDefaultDescription.title,
      action: errorActions.GO_TO_DASHBOARD,
      modal : false,
    },
    [dmpErrors.DMPPatientAccessOTPDeleteForbidden]: {
      message: 'Interdiction de supprimer le canal OTP. Il faut toujours au moins un canal de défini.',
      modal  : false,
    },
    [dmpErrors.DMPInvalidData]                    : {
      action      : errorActions.NONE,
      errorHandler: invalidPracticeLocationErrorHandler,
    },
    [dmpErrors.DMPPatientNotFound]                : {
      message: 'Patient inconnu (DMP non trouvé).',
      modal  : false,
    },
    [dmpErrors.DMPAccessForbidden]                : {
      message: 'Mode d’accès refusé par le patient (bris glace et/ou régulation)',
      title  : dmpErrorDefaultDescription.title,
      action : errorActions.GO_TO_DASHBOARD,
    },
  },
  [errorTypes.DmpConnectJsSoftwareErrors]  : {
    [jsSoftwareErrors.TECHNICAL_ERROR] : {
      title      : 'Une erreur technique s\'est produite.',
      message    : 'Merci de transmettre au service technique les informations ci-dessous.',
      action     : errorActions.CLOSE_SESSION,
      showDetails: true,
    },
    [jsSoftwareErrors.FUNCTIONAL_ERROR]: {
      title      : 'Une erreur fonctionnelle s\'est produite.',
      message    : 'Merci de transmettre au service technique les informations ci-dessous.',
      action     : errorActions.CLOSE_SESSION,
      showDetails: true,
    },
    [jsSoftwareErrors.EXPIRED_SESSION] : {
      title       : 'La session a expiré',
      message     : 'Vous allez être redirigé vers la page de connexion.',
      action      : errorActions.LOGIN,
      severity    : errorSeverities.LOW_SEVERITY,
      errorHandler: expiredSessionErrorHandler,
      modal       : true,
      logs        : false,
    },
    [jsSoftwareErrors.INVALID_PASSWORD]: {
      message: 'Mot de passe invalide',
      modal  : false,
    },
    [jsSoftwareErrors.INVALID_ES_USER] : {
      message: 'Identifiant invalide',
      modal  : false,
    },
    [jsSoftwareErrors.ES_NOT_FOUND]    : {
      title      : 'Erreur de configuration',
      message    : 'Etablissement inconnu',
      showDetails: true,
      severity   : errorSeverities.HIGH_SEVERITY,
      action     : errorActions.CLOSE_SESSION,
      modal      : false,
    },
  },
  [errorTypes.dmpConnectRestSoftwareErrors]: {
    [esrestSoftwareErrors.TECHNICAL_ERROR] : {
      title      : 'Une erreur technique s\'est produite.',
      message    : 'Merci de transmettre au service technique les informations ci-dessous.',
      action     : errorActions.CLOSE_SESSION,
      showDetails: true,
    },
    [esrestSoftwareErrors.FUNCTIONAL_ERROR]: {
      title      : 'Une erreur fonctionnelle s\'est produite.',
      message    : 'Merci de transmettre au service technique les informations ci-dessous.',
      action     : errorActions.CLOSE_SESSION,
      showDetails: true,
    },
  },
  [errorTypes.SoftwareErrors]              : {
    [softwareErrors.WEBSOCKET_TIMEOUT]                  : {
      title      : 'Délai de réponse dépassé',
      message    : 'Le connecteur DmpConnect-JS n\'a pas répondu dans les temps.',
      action     : errorActions.REFRESH_PAGE,
      showDetails: true,
      modal      : true,
    },
    [softwareErrors.WEBSOCKET_ERROR]                    : {
      title      : 'Erreur de connexion',
      message    : 'Erreur de connexion au connecteur DMPConnect-JS.',
      action     : errorActions.LOGIN,
      showDetails: true,
      modal      : true,
    },
    [softwareErrors.CPX_REMOVED]                        : {
      title   : 'Une erreur s\'est produite',
      message : 'La carte CPS a été retirée',
      severity: errorSeverities.HIGH_SEVERITY,
      action  : errorActions.CLOSE_SESSION,
      logs    : false,
    },
    [softwareErrors.INVALID_SEARCH_PARAMS]              : {
      title      : 'Une erreur s\'est produite',
      message    : 'Les paramètres fournis sont invalides',
      action     : errorActions.NONE,
      showDetails: true,
      logs       : false,
    },
    [softwareErrors.INS_NIR_INVALID_FORMAT]             : {
      title      : 'Une erreur s\'est produite',
      message    : (
        <>
          L&apos;insNir fourni est invalide.
          <br/>
          Le format attendu est : 15 chiffres + 1 lettre
        </>
      ),
      action     : errorActions.NONE,
      showDetails: true,
      logs       : false,
    },
    [softwareErrors.INSC_INVALID_FORMAT]                : {
      title      : 'Une erreur s\'est produite',
      message    : (
        <>
          L&apos;insC fourni est invalide.
          <br/>
          Le format attendu est : 22 chiffres
        </>
      ),
      action     : errorActions.NONE,
      showDetails: true,
      logs       : false,
    },
    [softwareErrors.DMPCONNECTJS_MISSING_OR_UNREACHABLE]: {
      title      : 'DmpConnect-JS inaccessible',
      message    : (
        <>
          Cette application nécessite l&apos;installation préalable du composant DmpConnect-JS (non
          trouvé).
          <br/>
          <br/>
          <a href="https://www.icanopee.fr" target="_blank" rel="noopener noreferrer">
            Lien de
            téléchargement
          </a>
        </>
      ),
      action     : errorActions.REFRESH_PAGE,
      modal      : true,
      showDetails: false,
      logs       : false,
    },
    [softwareErrors.DMPCONNECJS_WRONG_VERSION]          : {
      title      : 'Version DmpConnect-JS',
      message    :
        <>
          Une version trop ancienne du connecteur DmpConnect-JS est installée.
          <br/>
          <br/>
          <a href="https://www.icanopee.fr" target="_blank" rel="noopener noreferrer">
            Lien de
            téléchargement
          </a>
        </>,
      action     : errorActions.CLOSE_SESSION,
      showDetails: true,
      logs       : false,
    },
    [softwareErrors.UNEXPECTED_ERROR]                   : {
      title      : 'Une erreur technique s\'est produite.',
      message    : 'Merci de transmettre au service technique les informations ci-dessous.',
      action     : errorActions.REFRESH_PAGE,
      showDetails: true,
      modal      : true,
      severity   : errorSeverities.HIGH_SEVERITY,
    },
    [softwareErrors.DEMO_CPX_IN_PROD]                   : {
      title   : 'Carte invalide',
      modal   : true,
      message : 'Les cartes de démonstration ou de test ne permettent pas d\'accéder au DMP de production.',
      action  : errorActions.LOGIN,
      severity: errorSeverities.HIGH_SEVERITY,
      logs    : false,
    },
    [softwareErrors.WRONG_DC_PARAMS_CONFIG]             : {
      title   : 'Problème de configuration',
      modal   : true,
      message : 'L\'application n\'est pas autorisée à accéder au connecteur.',
      action  : errorActions.CLOSE_SESSION,
      severity: errorSeverities.HIGH_SEVERITY,
    },
    [softwareErrors.PARAMS_INVALID_JSON]                : {
      title      : 'Paramètres URL',
      modal      : true,
      showDetails: true,
      message    : 'Le format des paramètres fournis est invalide',
      action     : errorActions.CLOSE_SESSION,
      severity   : errorSeverities.HIGH_SEVERITY,
      logs       : false,
    },
    [softwareErrors.LOGINCHECK_MISSING_VALUE]           : {
      title      : 'Vérification Identité',
      modal      : true,
      showDetails: true,
      message    : 'Les paramètres de vérification d\'identité fournis sont incomplets',
      action     : errorActions.CLOSE_SESSION,
      severity   : errorSeverities.HIGH_SEVERITY,
      logs       : false,
    },
    [softwareErrors.CONNECTOR_REST_UNREACHABLE]         : {
      title      : 'Connecteur inaccessible',
      message    : 'Le connecteur ne semble pas actif',
      action     : errorActions.NONE,
      showDetails: false,
      severity   : errorSeverities.HIGH_SEVERITY,
      logs       : false,
    },
    [softwareErrors.API_LOGIN_MISSING_VALUE]            : {
      title      : 'Erreur de connexion',
      message    : 'Des informations nécessaires à la connexion sont manquantes',
      action     : errorActions.LOGIN,
      showDetails: true,
      severity   : errorSeverities.HIGH_SEVERITY,
      logs       : false,
    },
    [softwareErrors.AIR_AUTH_MODE_INVALID]              : {
      title      : 'Problème de configuration',
      message    : 'Le mode d\'authentification air fourni est invalide',
      action     : errorActions.LOGIN,
      showDetails: true,
      severity   : errorSeverities.HIGH_SEVERITY,
      logs       : false,
    },
    [softwareErrors.NO_PRACTICE_LOCATIONS]              : {
      title      : 'Carte invalide',
      message    : (
        <>
          Cette carte ne définit aucune situation d&apos;exercice.
          <br/>
          L&apos;accès au DMP n&apos;est pas possible dans ce cas.
          <br/>
          <br/>
          Le cas des cartes de professionnels en formation (CPF) sera traité très prochainement.
        </>
      ),
      severity   : errorSeverities.HIGH_SEVERITY,
      action     : errorActions.CLOSE_SESSION,
      showDetails: true,
      logs       : false,
    },
    [softwareErrors.JWT_SESSION_EXPIRED]                : {
      title      : 'Session OPENID expirée',
      message    : (
        <>
          La session OpenID a expiré.
          <br/>
          Vous devez vous reconnecter.
        </>
      ),
      severity   : errorSeverities.HIGH_SEVERITY,
      action     : errorActions.NONE,
      showDetails: false,
      logs       : false,
    },
    [softwareErrors.INVALID_ES_SETTINGS]                : {
      title      : 'Configuration ES invalide',
      message    : 'La configuration d\'établissement fournie est invalide.',
      severity   : errorSeverities.HIGH_SEVERITY,
      action     : errorActions.NONE,
      showDetails: true,
      logs       : false,
    },
    [softwareErrors.WEBAPP_UPDATED]                     : {
      title   : 'Mise à jour de l\'application détectée',
      message : 'Réinitialisation de l\'application nécessaire.',
      severity: errorSeverities.HIGH_SEVERITY,
      action  : errorActions.REINITIALIZE,
      logs    : false,
    },
    [softwareErrors.INVALID_MSS_OPERATORS_CONFIG]       : {
      title      : 'Opérateurs MSS invalides',
      message    : 'La configuration des opérateurs MSS fournie est invalide.',
      severity   : errorSeverities.HIGH_SEVERITY,
      action     : errorActions.NONE,
      showDetails: true,
      logs       : false,
    },
  },
  [errorTypes.VitaleErrors]                : {
    [vitaleErrors.ERR_CARTE_ABSENTE] : {
      title  : 'Aucune carte dans le lecteur',
      message: '',
      modal  : false,
    },
    [vitaleErrors.ERR_CARTE]         : {
      title  : 'Erreur lors la lecture de la carte',
      message: '',
      modal  : false,
    },
    [vitaleErrors.ERR_LECTEUR]       : {
      title  : 'Erreur avec le lecteur de carte',
      message: '',
      modal  : false,
    },
    [vitaleErrors.ERR_CARTE_INCONNUE]: {
      title  : 'Carte inconnue',
      message: '',
      modal  : false,
    },
    [vitaleErrors.ERR_CARTE_MUETTE]  : {
      title  : 'Carte muette',
      message: '',
      modal  : false,
    },
    [vitaleErrors.ERR_CARTE_INVALIDE]: {
      title  : 'Carte invalide',
      message: '',
      modal  : false,
    },
    [vitaleErrors.ERR_TIMEOUT]       : {
      title  : 'Aucune carte dans le lecteur',
      message: '',
      modal  : false,
    },
  },
  [errorTypes.JWTErrors]                   : {
    [jwtErrors.INVALID_TOKEN]     : {
      title   : 'Erreur JWT',
      message : 'Le jeton fourni n\'est pas valide.',
      action  : errorActions.LOGIN,
      severity: errorSeverities.HIGH_SEVERITY,
      modal   : true,
      logs    : false,
    },
    [jwtErrors.EXPIRED_TOKEN]     : {
      title   : 'Erreur JWT',
      message : (<>
        Le jeton fourni a expiré
        <br/>
        En attente d'un nouveau jeton.
      </>),
      action  : errorActions.LOGIN,
      severity: errorSeverities.HIGH_SEVERITY,
      modal   : true,
      logs    : false,
    },
    [jwtErrors.MISSING_CLAIMS]    : {
      title   : 'Erreur JWT',
      message : 'Des données sont manquantes dans le jeton.',
      action  : errorActions.LOGIN,
      severity: errorSeverities.HIGH_SEVERITY,
      modal   : true,
      logs    : false,
    },
    [jwtErrors.INVALID_USER_INFOS]: {
      title   : 'Erreur JWT',
      message : 'Les données du PS sont invalides',
      action  : errorActions.LOGIN,
      severity: errorSeverities.HIGH_SEVERITY,
      modal   : true,
      logs    : false,
    },
    [jwtErrors.INVALID_SIGNATURE] : {
      title   : 'Erreur JWT',
      message : 'La signature du jeton est invalide.',
      action  : errorActions.LOGIN,
      severity: errorSeverities.HIGH_SEVERITY,
      modal   : true,
      logs    : false,
    },
    [jwtErrors.EMPTY_TOKEN]       : {
      title   : 'Erreur JWT',
      message : 'Aucun jeton fourni',
      action  : errorActions.LOGIN,
      severity: errorSeverities.HIGH_SEVERITY,
      modal   : true,
      logs    : false,
    },
  },
  [errorTypes.DPErrors]                    : {
    [dpErrors.NO_DP]     : {
      ...dpErrorDefaultDescription,
      message: 'Le patient ne dispose pas de dossier pharmaceutique',
      modal  : false,
      logs   : false,
    },
    [dpErrors.DP_REFUSED]: {
      ...dpErrorDefaultDescription,
      message: 'Le patient ne dispose pas de dossier pharmaceutique et a refusé sa création.',
      modal  : false,
      logs   : false,
    },
    [dpErrors.DP_DELETED]: {
      ...dpErrorDefaultDescription,
      message: 'Le DP du patient a été supprimé, ainsi que l\'historique de dispensations',
      modal  : false,
      logs   : false,
    },
  },
  [errorTypes.apCvErrors]                  : {
    [apCvErrors.WRONG_CARD_TYPES]: {
      title  : 'Erreur ApCV',
      message: undefined,
      modal  : false,
    },
  },
  [errorTypes.openIDErrors]                : {
    // auth
    [openIDErrors.invalid_client]       : {
      ...openIDErrorDefaultDescription,
      message: 'Les identifiants sont invalides.',
    },
    [openIDErrors.invalid_uri]          : {
      ...openIDErrorDefaultDescription,
      message: 'L\'adresse de redirection est invalide.',
    },
    [openIDErrors.redirect_uri_mismatch]: {
      ...openIDErrorDefaultDescription,
      message: 'L\'adresse de redirection est invalide.',
    },
    
    // token
    [openIDErrors.invalid_request]            : {
      ...openIDErrorDefaultDescription,
      message: 'La requête de demande de jeton est invalide.',
    },
    [openIDErrors.unsupported_grant_type]     : {
      ...openIDErrorDefaultDescription,
      message: 'Le type d\'autorisation demandé n\'est pas supporté.',
    },
    [openIDErrors.invalid_grant]              : {
      ...openIDErrorDefaultDescription,
      message: 'Le code d’autorisation n’existe pas ou est invalide.',
    },
    [openIDErrors.unauthorized_client]        : {
      ...openIDErrorDefaultDescription,
      message: 'Le type d\'autorisation demandé n\'est pas autorisé pour client_id.',
    },
    [openIDErrors.invalid_scope]              : {
      ...openIDErrorDefaultDescription,
      message: 'La portée d’autorisation demandée est invalide.',
    },
    [openIDErrors.invalid_token]              : {
      ...openIDErrorDefaultDescription,
      message: 'Le token est invalide',
    },
    [openIDErrors.login_check_failed]         : {
      ...openIDErrorDefaultDescription,
      message: 'La vérification d\'identité a échoué',
    },
    [openIDErrors.missing_login_check]        : {
      ...openIDErrorDefaultDescription,
      message: 'Traits d\'identité à vérifier sont manquants',
    },
    [openIDErrors.missing_auth_callback_value]: {
      ...openIDErrorDefaultDescription,
      message: 'Impossible de traiter la réponse d\'authentification',
    },
  },
  [errorTypes.httpErrors]: {
    [httpErrors.failed_to_fetch]: {
      ...httpErrorDefaultDescription,
      message: 'Erreur lors de l\'accès au service',
    }
  }
};
